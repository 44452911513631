import React, { useRef, useEffect } from 'react'
import useChartDimensions from '../../hooks/useChartDimensions'
import * as d3 from 'd3'
import Layout from '../../components/layout'
import data from '../../data/coal-in-america/us-historical-coal-emissions.json' 


const EnergyPriceHistory = () => {

  // d3.csv("https://raw.githubusercontent.com/carbon-switch/datasets/514a37352a77684a326f5d17eaee01079847b380/natural-gas-price-history", function(gasPrices) {console.log(gasPrices)})

  const wrapperRef = useRef()
  const yAxisRef = useRef()
  const xAxisRef = useRef()
  const lineRef = useRef()
  const dimensions = useChartDimensions(wrapperRef)

  const dateParser = d3.timeParse("%Y")
  const xAccessor = d => dateParser(d.x) 
  
  const xScale = d3.scaleTime()
    .domain(d3.extent(data, xAccessor))
    .range([0, dimensions.width])
  
  const yScale = d3.scaleLinear()
    .domain([500000000, 2500000000])
    .range([dimensions.height, 0])
  
  const lineGenerator = d3.line()
    .x(d => xScale(xAccessor(d)))
    .y(d => yScale(d.y))
  
  const xAxisGenerator = d3.axisBottom()
    .scale(xScale)
    .ticks(dimensions.width / 100)
  
  const yAxisGenerator = d3.axisLeft()
    .scale(yScale)
    .tickFormat(d3.format(".2s"))

  useEffect(() => {

    const yAxisG = d3.select(yAxisRef.current)
      .join("g")

    const xAxisG = d3.select(xAxisRef.current)
      .join("g")
        .style("transform", `translateY(${dimensions.height}px)`)

    yAxisGenerator(yAxisG) 
    xAxisGenerator(xAxisG)

    d3.select(lineRef.current)
      .join("path")
        .attr("d", lineGenerator(data))
        .attr("fill", "none")
        .attr("stroke", "#2F394B")
        .attr("stroke-width", 2)
    
  }, [dimensions])

  return ( 
    <Layout>
      <div className='min-h-screen py-24'>
        <div 
          ref={wrapperRef} 
          id={"responsive-div-wrapper"}
          className="mx-auto w-3/4 h-[600px]"
        >
          <div className="mb-2">
            <p className="font-light text-base">CO2 emissions from coal-fired power plants in the U.S. (gigatons per year)</p>
          </div>
          <svg
            className="w-full h-full overflow-visible"
            id={"svg-wrapper"}
          >
            <g id={"axes-group"}>
              <g id={"y-group"} ref={yAxisRef}></g>
              <g id={"x-group"} ref={xAxisRef}></g>
            </g>
            <g id={"line-group"}>
              <path id={"line"} ref={lineRef}></path>
            </g>
          </svg>
        </div>
      </div>
    </Layout>
  )
}

export default EnergyPriceHistory